import { useQuery } from '@apollo/client';
import { EVENTS_PAGE_SIZE } from 'constants/events';
import { MatchTypesEnum } from 'constants/match';
import { GET_BUYER_UPCOMING_EVENTS } from 'gql/events/queries';
import { BuyerListMatches } from 'gql/events/types/BuyerListMatches';
import { useProjectStateContext } from 'providers/Projects';
import { useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setEventsLoading,
  setEventsTotal,
  setIsPlayDisabled
} from 'store/slice/filters';
import {
  selectEventsPagination,
  selectFormattedEventsFilters
} from 'store/slice/filters/events/selector';

import useInfiniteScroll from './useInfiniteScroll';

const useEventsUpcomingCache = () => {
  const { selectedProject } = useProjectStateContext();
  // redux
  const dispatch = useAppDispatch();
  const upcomingFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.list),
    shallowEqual
  );

  const upcomingFiltersRef = useRef(upcomingFilters);

  useEffect(() => {
    upcomingFiltersRef.current = upcomingFilters;
  }, [upcomingFilters]);

  const { page } = useAppSelector(selectEventsPagination(MatchTypesEnum.list));

  const { data: upcoming, fetchMore } = useQuery<BuyerListMatches>(
    GET_BUYER_UPCOMING_EVENTS,
    {
      fetchPolicy: 'cache-only',
      variables: {
        query: {
          pagination: {
            page,
            limit: EVENTS_PAGE_SIZE
          },
          ...upcomingFilters
        },
        organizationId: selectedProject?.id,
        partnerId: selectedProject?.partnerId
      },
      onCompleted(data) {
        const total = data.buyerListMatches?.data.total || 0;
        dispatch(setEventsTotal({ type: MatchTypesEnum.list, value: total }));
        dispatch(
          setIsPlayDisabled({ type: MatchTypesEnum.list, value: false })
        );

        if (!total) {
          dispatch(
            setEventsLoading({ type: MatchTypesEnum.list, value: false })
          );
        }
      }
    }
  );

  const upcomingResults = useMemo(
    () => upcoming?.buyerListMatches?.data.results || [],
    [upcoming?.buyerListMatches?.data.results]
  );

  const upcomingTotal = useMemo(
    () => upcoming?.buyerListMatches?.data.total || 0,
    [upcoming?.buyerListMatches?.data.total]
  );

  // scroll
  const { onScroll, stopPaging, startPaging } = useInfiniteScroll({
    callback: page => {
      if (!page) return Promise.resolve();

      const updatedFilters = upcomingFiltersRef.current;

      return fetchMore({
        variables: {
          query: {
            pagination: {
              page,
              limit: EVENTS_PAGE_SIZE
            },
            ...updatedFilters
          },
          organizationId: selectedProject?.id,
          partnerId: selectedProject?.partnerId
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newResults =
            fetchMoreResult.buyerListMatches?.data.results || [];

          if (!prev.buyerListMatches?.data.results) return prev;

          return {
            ...prev,
            buyerListMatches: {
              ...prev.buyerListMatches,
              data: {
                ...prev.buyerListMatches.data,
                results: [...prev.buyerListMatches.data.results, ...newResults]
              }
            }
          };
        }
      });
    }
  });

  useEffect(() => {
    if (
      upcomingResults?.length &&
      upcomingTotal &&
      upcomingResults.length >= upcomingTotal
    ) {
      stopPaging();
    } else startPaging();
  }, [stopPaging, upcomingResults.length, upcomingTotal, startPaging]);

  return {
    results: upcomingResults,
    total: upcomingTotal,
    onScroll: onScroll
  };
};

export default useEventsUpcomingCache;
