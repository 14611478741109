import styled, { utils } from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import { css } from 'styled-components';

import { IStyledConfirmModalProps, IStyledMassageProps } from './types';

export const StyledConfirmModal = styled.div<IStyledConfirmModalProps>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 11, 27, 0.85);
  ${({ isVisible }) => `display: ${isVisible ? 'flex' : 'none'}`}
`;

export const StyledModalWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.light[0]};
    border-radius: 6px;
    min-width: 500px;
    max-width: 500px;
    filter: drop-shadow(0 ${utils.rem(3)} ${utils.rem(6)})
      ${rgba('#000000', 0.1)};
  `}
`;

export const StyledModalHeader = styled.div<{ titleColor?: string }>`
  display: flex;
  justify-content: center;
  font-weight: bold;
  position: relative;
  ${({ theme, titleColor }) => css`
    padding: ${theme.sizes.xxl} ${theme.sizes.xxl} 0 ${theme.sizes.xxl};
    font-size: ${theme.sizes.lg};
    color: ${titleColor || theme.colors.blue[8]};
  `}
`;

const StyledIcon = css`
  width: ${utils.rem(80)};
  flex: 1 0 ${utils.rem(80)};
  height: ${utils.rem(80)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDangerContainer = styled.div`
  ${StyledIcon};
  ${({ theme }) => css`
    background-color: ${rgba(theme.colors.rose[1], 0.2)};
  `}
`;

export const StyledWarningContainer = styled.div`
  ${StyledIcon};
  ${({ theme }) => css`
    background-color: ${rgba(theme.colors.primary, 0.2)};
  `}
`;

export const StyledSuccessContainer = styled.div`
  ${StyledIcon};
`;

export const StyledMessage = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.sizes.base};
    color: ${theme.colors.blue[8]};
  `}
  text-align: center;
`;

export const StyledModalFooter = styled.div`
  border-top: 1px dashed rgba(8, 16, 77, 0.08);
  padding: 0;
  ${({ theme }) => css`
    padding: ${theme.spaces.xl} 80px;
  `}
`;
export const StyledMessageReason = styled.span<IStyledMassageProps>`
  text-align: center;
  ${({ massageColor, theme }) => css`
    font-size: ${theme.sizes.base};
    color: ${massageColor ? theme.colors.red[2] : theme.colors.red[8]};
  `}
`;
